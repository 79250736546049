import $ from "jquery";
import {
    useNavigate,
  } from "react-router-dom";
  

export const isLoggedIn = () => {
    return !(localStorage.getItem("token") === null);
  }

  export function LoginCheck()  {
    let navigate = useNavigate();
    const URL = process.env.REACT_APP_SITE;
    $.ajax({ url: URL + "me", headers: { token: localStorage.getItem("token") }, success: function (data) {
        localStorage.setItem("groupID", data.GroupID);
        localStorage.setItem("groupName", data.GroupName);
    }, dataType: "json"})
        .fail(function () {
            localStorage.removeItem("token");
            localStorage.removeItem("userID");
            localStorage.removeItem("fullName");
            localStorage.removeItem("groupID");
            localStorage.removeItem("groupName");
            navigate("/login");
        });
  }