import { useState } from "react";
import ReactModal from "react-modal";
import $ from "jquery";
import { CgClose } from "react-icons/cg";

export default function SelectMediaModal(props) {
  const id = props.id;
  const open = props.open;
  const setOpen = props.setOpen;
  const handleDownload = props.handleDownload;

  const [mediaFiles, setMediaFiles] = useState(null);
  const [selected, setSelected] = useState([]);

  function downloadData() {
    const URL = process.env.REACT_APP_SITE;
    $.ajax({
      url: URL + "getEvent?eventId=" + id,
      headers: { token: localStorage.getItem("token") },
      success: function (data) {
        setMediaFiles(
          data.Media.map((media) => {
            return {
              fileName: media.FileName,
              thumbnailUrl: media.ThumbnailURL,
              idMedia: media.idMedia,
            };
          })
        );
      },
      dataType: "json",
    });
  }

  return (
    <ReactModal
      isOpen={open}
      className={"R_Modal_Scrollable ignoreOpenEvent"}
      ariaHideApp={false}
      onRequestClose={() => {
        setMediaFiles(null);
        setOpen(false);
        setSelected([]);
      }}
      onAfterOpen={downloadData}
    >
      <div className="ignoreOpenEvent eventSetting">
        <div className="downloadButtons">
          <button
            onClick={() => {
              selected.length === mediaFiles?.length
                ? setSelected([])
                : setSelected(mediaFiles?.map(({ fileName }) => fileName));
            }}
            id={`selectAllButton` + id}
            className="btn btn-primary ignoreOpenEvent"
          >
            {selected.length === mediaFiles?.length ? "Semmi" : "Összes"}
          </button>
          <button
            onClick={() => {
              handleDownload(selected);
              setOpen(false);
              setMediaFiles(null);
            }}
            id={`downloadButton` + id}
            className="btn btn-primary ignoreOpenEvent"
            disabled={selected.length === 0}
          >
            Letöltés
          </button>
        </div>
        <CgClose
          size={30}
          color={"black"}
          className={"ignoreOpenEvent"}
          onClick={() => {
            setMediaFiles(null);
            setOpen(false);
            setSelected([]);
          }}
        />
      </div>

      <div className="wrapSelectList ignoreOpenEvent">
        {mediaFiles
          ? mediaFiles.map((media, index) => {
              return (
                <div
                  style={{
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: "red",
                  }}
                  className={"ignoreOpenEvent"}
                  key={index}
                >
                  <img
                    key={"modalThumbnail" + index}
                    style={{ height: 200, margin: "5px", cursor: "pointer" }}
                    alt={media.idMedia}
                    src={media.thumbnailUrl}
                    className={`ignoreOpenEvent${
                      selected.includes(media.fileName)
                        ? " selectedMedia"
                        : " notSelectedMedia"
                    }`}
                    onClick={() =>
                      selected.includes(media.fileName)
                        ? setSelected((selected) =>
                            selected.filter(
                              (fileName) => fileName !== media.fileName
                            )
                          )
                        : setSelected((selected) => [
                            ...selected,
                            media.fileName,
                          ])
                    }
                  />
                </div>
              );
            })
          : null}
      </div>
    </ReactModal>
  );
}
