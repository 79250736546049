import Header from "../components/Header";

function Settings() {
    return (
      <div>
          <Header />
          <h1>Settings</h1>
      </div>
    );
  }
  
  export default Settings;
  
