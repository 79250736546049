import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import {
  Download,
  Inventory,
  PersonAdd,
  Delete,
  Logout,
} from "@mui/icons-material";
import RegistrationDialog from "./RegistrationDialog";
import toast, { Toaster } from "react-hot-toast";
import DownloadApkModal from "./DownloadApkModal";
import { Tooltip } from "@mui/material";
import { useResolvedPath } from "react-router-dom";

function logout(navigate) {
  localStorage.removeItem("token");
  localStorage.removeItem("userID");
  localStorage.removeItem("fullName");
  localStorage.removeItem("groupID");
  localStorage.removeItem("groupName");
  navigate("/login");
}

function Header() {
  let navigate = useNavigate();
  const pathName = useResolvedPath().pathname.split("/")[1];

  const [openDialog, setOpenDialog] = useState(false);
  const [openDownloadApkDialog, setOpenDownloadApkDialog] = useState(false);

  const handleDialogClose = (value) => {
    if (value) {
      value.error
        ? toast.error(value.errorMessage)
        : toast.success(value.status);
    }

    setOpenDialog(false);
  };

  useEffect(() => {
    if (pathName === "archive") {
      document.title = "Médiatár - Archívum";
      return;
    }
    if (pathName === "deleted") {
      document.title = "Médiatár - Lomtár";
      return;
    }
    document.title = "Médiatár";
  }, [pathName]);

  const NAME = process.env.REACT_APP_NAME;

  return (
    <div className="Header">
      <Toaster position="top-center" />
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand
            href="/"
            className={pathName === "home" ? "activeView" : ""}
          >
            <h1
              style={{
                fontWeight: pathName === "home" ? "bold" : "normal",
                color: pathName === "home" ? "lightgray" : "dodgerblue",
              }}
              title={"Főoldal"}
            >
              {NAME} Médiatár
            </h1>
          </Navbar.Brand>
          <Navbar.Collapse>
            <Navbar.Text style={{ fontSize: 25 }}>
              Üdv, {localStorage.getItem("fullName")}!
            </Navbar.Text>
          </Navbar.Collapse>
          <Nav>
            <Nav.Item style={{ alignSelf: "center" }} className="nav-link">
              <div
                title={"Alkalmazás letöltése"}
                style={{
                  display: "flex",
                  alignContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setOpenDownloadApkDialog(true)}
              >
                <Download sx={{ color: "dodgerblue" }} />
              </div>
            </Nav.Item>
            <DownloadApkModal
              open={openDownloadApkDialog}
              onClose={() => setOpenDownloadApkDialog(false)}
            />
            {localStorage.getItem("groupID") === "0" ? (
              <Nav.Item style={{ alignSelf: "center" }} className="nav-link">
                <div
                  title={"Új felhasználó"}
                  style={{
                    display: "flex",
                    alignContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenDialog(true)}
                >
                  <PersonAdd sx={{ color: "dodgerblue" }} />
                </div>
              </Nav.Item>
            ) : null}
            <RegistrationDialog open={openDialog} onClose={handleDialogClose} />
            <Nav.Link
              key="archiveButton"
              href="/archive"
              style={{ alignSelf: "center" }}
              className={pathName === "archive" ? "activeView" : ""}
            >
              <div title={"Archívum"}>
                <Inventory
                  fontSize={pathName === "archive" ? "large" : "medium"}
                  sx={{
                    color: pathName === "archive" ? "lightgray" : "dodgerblue",
                  }}
                />
              </div>
            </Nav.Link>
            <Nav.Link
              key="binButton"
              href="/deleted"
              style={{ alignSelf: "center" }}
              className={pathName === "deleted" ? "activeView" : ""}
            >
              <div title={"Lomtár"}>
                <Delete
                  fontSize={pathName === "deleted" ? "large" : "medium"}
                  sx={{
                    color: pathName === "deleted" ? "lightgray" : "dodgerblue",
                  }}
                />
              </div>
            </Nav.Link>
            {/* <Nav.Link key="settingsButton" href="/settings" style={{ fontSize: 25, paddingLeft: 30 }}>Settings</Nav.Link> */}
            <Nav.Link
              key="logoutButton"
              onClick={() => logout(navigate)}
              style={{ alignSelf: "center" }}
            >
              <div title={"Kijelentkezés"}>
                <Logout sx={{ color: "red" }} />
              </div>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
