import $ from "jquery";
import MakeZIPComponent from "./MakeZIPComponent";
import EventOptions from "./EventOptions";
import { useResolvedPath } from "react-router-dom";

function endDate(event) {
  if (event.StoreEnds || event.Thumbnails?.some((media) => media.StoreEnds)) {
    const dates = [
      ...(event.StoreEnds ? [new Date(event.StoreEnds)] : []),
      ...(event.Thumbnails?.some((media) => media.StoreEnds)
        ? event.Thumbnails?.filter((media) => media.StoreEnds).map(
            (media) => new Date(media.StoreEnds)
          )
        : []),
    ];
    const minDate = new Date(Math.min(...dates));
    return "Elérhető eddig: " + minDate.toLocaleDateString();
  }
  if (event.ArchiveEnds)
    return " Archív eddig: " + new Date(event.ArchiveEnds).toLocaleDateString();
  return "";
}

export default function EventCard(props) {
  const event = props.event;
  const eventClicked = props.eventClicked;
  const onMouseEnter = props.onMouseEnter;
  const onMouseLeave = props.onMouseLeave;
  const downloadData = props.downloadData;

  const pathName = useResolvedPath().pathname.split("/");
  const URL = process.env.REACT_APP_SITE;

  return (
    <div
      className="card className="
      id={`card${event.EventID}`}
      key={`card${event.EventID}`}
      onClick={(e) => {
        if (
          !(
            $(e.target).hasClass("ignoreOpenEvent") ||
            $(e.target).hasClass("ReactModal__Overlay") ||
            $(e.target).hasClass("react-datepicker__day") ||
            $(e.target).hasClass("react-datepicker__week") ||
            $(e.target).hasClass("react-datepicker__month") ||
            $(e.target).hasClass("react-datepicker__navigation") ||
            $(e.target).hasClass("react-datepicker__navigation-icon") ||
            $(e.target).hasClass("react-datepicker__day-name") ||
            $(e.target).hasClass("react-datepicker__current-month") ||
            $(e.target).is("path")
          )
        ) {
          eventClicked(
            e,
            event.EventID,
            pathName.includes("deleted") ? `/showDeletedMedia` : ""
          );
        }
      }}
      onMouseEnter={() => onMouseEnter(event.EventID)}
      onMouseLeave={() => onMouseLeave(event.EventID)}
      style={{
        backgroundColor: "WhiteSmoke",
        margin: "30px",
        marginTop: "15px",
        marginBottom: "15px",
        cursor: "pointer",
      }}
    >
      <div className="card-header">
        <span style={{ float: "left" }}>{event.EventDate.slice(0, -3)}</span>
        <span style={{ color: "red", marginLeft: 5 }}>{endDate(event)}</span>
        {pathName.includes("deleted") ? null : (
          <MakeZIPComponent event={event} kbSize={2000} blur={true} />
        )}
      </div>
      <div className="card-body">
        <h4 style={{ display: "inline-block" }} className="card-title">
          {event.LicensePlate === "" ? event.EventDate : event.LicensePlate}
          {localStorage.getItem("groupID") === "0" ? ` (${event.EventID})` : ""}
        </h4>
        <EventOptions event={event} onEventStateChange={downloadData} />
        <hr />
        {[
          ...event.Thumbnails.slice(0, 8).map((tn, index) => (
            <img
              key={event.EventID + "_2" + index}
              style={{ height: 150, marginLeft: "10px" }}
              alt={tn.ID}
              src={tn.URL}
              onError={({ target }) =>
                (target.src = URL + "public/fileMissing.png")
              }
            />
          )),
          event.Thumbnails.length > 9 ? (
            <h1
              key={event.EventID + "_1etc"}
              style={{
                display: "inline-block",
                height: 100,
                marginLeft: "35px",
                fontSize: 80,
              }}
            >
              ...
            </h1>
          ) : null,
        ]}
      </div>
    </div>
  );
}
