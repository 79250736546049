import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./index.css";

import Login from "./routes/login";
import Home from "./routes/home";
import Search from "./routes/search";
import EventPage from "./routes/event";
import Settings from "./routes/settings";
import { isLoggedIn } from "./static";
import "bootstrap/dist/css/bootstrap.css";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<GoToHome />} />
      <Route path="login" element={<Login />} />
      <Route
        path="home"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      >
        <Route
          path=":pageNum"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path="archive"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      >
        <Route
          path=":pageNum"
          element={
            <RequireAuth>
              <Home archive/>
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path="deleted"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      >
        <Route
          path=":pageNum"
          element={
            <RequireAuth>
              <Home/>
            </RequireAuth>
          }
        />
      </Route>
      <Route
        path="settings"
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route path="event" element={<GoToHome />} />
      <Route
        path="event/:eventId"
        element={
          <RequireAuth>
            <EventPage />
          </RequireAuth>
        }
      />
      <Route
        path="event/:eventId/:show"
        element={
          <RequireAuth>
            <EventPage />
          </RequireAuth>
        }
      />
      <Route
        path="search"
        element={
          <RequireAuth>
            <Search />
          </RequireAuth>
        }
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

function RequireAuth({ children }) {
  let location = useLocation();
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

function GoToHome() {
  let location = useLocation();
  return (
    <RequireAuth>
      <Navigate to="/home" state={{ from: location }} />
    </RequireAuth>
  );
}
