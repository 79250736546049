import { useState } from "react";
import React from 'react';
import {
    useNavigate,
  } from "react-router-dom";
import $ from "jquery";

function Login() {
    const [inputs, setInputs] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    let navigate = useNavigate();

    const URL = process.env.REACT_APP_SITE;
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        $.post(URL + "login", { username: inputs.username, password: inputs.password }, function (data) {
            setErrorMessage("");
            localStorage.setItem("token", data.Token);
            localStorage.setItem("userID", data.UserID);
            localStorage.setItem("fullName", data.FullName);
            localStorage.setItem("groupID", data.GroupID);
            localStorage.setItem("groupName", data.GroupName);
            navigate("/");
        }, "json")
            .fail(function () {
                setErrorMessage("Rossz felhasználónév vagy jelszó");
            });
    }

    return (
        <div className="d-flex align-items-center justify-content-center align-middle" style={{fontSize: 20 , textAlign: 'center', height: '100vh'}}>
        <form onSubmit={(e) => handleSubmit(e)} style={{padding: 10}}>
            <h1>Médiatár web</h1>
            <br />
                <input style={{marginTop: 20}}
                    type="text"
                    name="username"
                    value={inputs.username || ""}
                    onChange={handleChange}
                    placeholder="Felhasználónév"
                />
            <br />
                <input style={{marginTop: 10}}
                    type="password"
                    name="password"
                    value={inputs.password || ""}
                    onChange={handleChange}
                    placeholder="Jelszó"
                />
            <br />
            <input style={{marginTop: 20}} type="submit" value={"Bejelentkezés"}/>
            <br/>
            {errorMessage && (
                <p className="error"> {errorMessage} </p>
            )}
        </form>
        </div>
    )
}

export default Login;
