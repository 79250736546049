import Header from "../components/Header";
import $ from "jquery";
import { useState, useEffect } from "react";
import MakeZIPComponent from "../components/MakeZIPComponent";

function downloadZIP(id) {
  $("#downloadButton" + id).prop("disabled", true);
  $("#downloadButton" + id).text("Várakozás...");
  const URL = process.env.REACT_APP_SITE;
  $.ajax({
    url: URL + `makeZIP?eventID=${id}`,
    headers: { token: localStorage.getItem("token") },
    xhrFields: {
      responseType: "blob",
    },
    success: function (data) {
      var a = document.createElement("a");
      var url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = `Event${id}.zip`;
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      $("#downloadButton" + id).prop("disabled", false);
      $("#downloadButton" + id).text("Letöltés");
    },
  });
}

function enterBack(id) {
  $("#card" + id).css("background-color", "LightGray");
}

function leaveBack(id) {
  $("#card" + id).css("background-color", "WhiteSmoke");
}

function eventClicked(ev, id) {
  if (ev.target.nodeName !== "BUTTON") {
    window.open("/event/" + id, "_self");
  }
}

function Search() {
  let [query, setQuery] = useState("");
  let [events, setEvents] = useState([]);
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (query.length > 0) {
        const URL = process.env.REACT_APP_SITE;
        $.ajax({
          url: URL + "searchEvent?word=" + query,
          headers: { token: localStorage.getItem("token") },
          success: function (data) {
            setEvents(data);
          },
          dataType: "json",
        });
      } else {
        setEvents([]);
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  return (
    <div>
      <Header />
      <div className="form-outline" style={{ textAlign: "center" }}>
        <input
          type="search"
          id="search"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          className="form-control"
          style={{
            fontSize: 25,
            width: "80%",
            marginTop: "30px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          placeholder="Keresés rendszám alapján"
          aria-label="Search"
        />
      </div>
      {events.map((event) => (
        <div
          key={event.EventID}
          className="card className="
          id={`card${event.EventID}`}
          onClick={(e) => {
            if (
              !(
                $(e.target).hasClass("ignoreOpenEvent") ||
                $(e.target).hasClass("ReactModal__Overlay") ||
                $(e.target).hasClass("react-datepicker__day") ||
                $(e.target).hasClass("react-datepicker__week") ||
                $(e.target).hasClass("react-datepicker__month") ||
                $(e.target).hasClass("react-datepicker__navigation") ||
                $(e.target).hasClass("react-datepicker__navigation-icon") ||
                $(e.target).hasClass("react-datepicker__day-name") ||
                $(e.target).hasClass("react-datepicker__current-month") ||
                $(e.target).is("path")
              )
            ) {
              eventClicked(e, event.EventID);
            }
          }}
          onMouseEnter={() => enterBack(event.EventID)}
          onMouseLeave={() => leaveBack(event.EventID)}
          style={{
            backgroundColor: "WhiteSmoke",
            margin: "30px",
            marginBottom: "50px",
            cursor: "pointer",
          }}
        >
          <div className="card-header">
            <span style={{ float: "left" }}>
              {event.EventDate.slice(0, -3)}
            </span>
            <MakeZIPComponent event={event} kbSize={2000} blur={true} />
          </div>
          <div className="card-body">
            <h4 style={{ display: "inline-block" }} className="card-title">
              {event.LicensePlate === "" ? event.EventDate : event.LicensePlate}
            </h4>
            <hr />
            {event.Thumbnails.map((tn, index) =>
              event.Thumbnails.length > 9 && index > 8 ? (
                <h1
                  key={event.EventID + "_1" + index}
                  style={{
                    display: "inline-block",
                    height: 100,
                    marginLeft: "35px",
                    fontSize: 80,
                  }}
                >
                  ...
                </h1>
              ) : (
                <img
                  key={event.EventID + "_2" + index}
                  style={{ height: 150, marginLeft: "10px" }}
                  alt={tn.ID}
                  src={tn.URL}
                />
              )
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Search;
