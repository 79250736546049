import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import ReactSectionList from "react-sectionlist";
import { confirm } from "../components/Confirmation";

export default function ShareWithModal(props) {
  const isOpen = props.isOpen;
  const setState = props.setState;
  const users = props.users;
  const sharedWith = props.sharedWith;
  const onSave = props.onSave;

  const [changed, setChanged] = useState(false);
  const [sharedWithState, setSharedWithState] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSharedWithState(sharedWith);
  }, [sharedWith]);

  const section = (sectionData, sectionHeader) => {
    const groupIds = sectionData.map((item) => item[sectionHeader]);
    const sectionWithoutData = groupIds
      .filter((groupId, index) => groupIds.indexOf(groupId) === index)
      .map((groupId) => ({ title: groupId }));
    return sectionWithoutData.map((group) => ({
      ...group,
      data: users
        .filter(
          (user) =>
            user.GroupID === group.title &&
            user.FullName.toLowerCase().includes(searchValue)
        )
        .map((item) => {
          const newItem = { ...item };
          delete newItem[sectionHeader];
          return newItem;
        }),
    }));
  };

  const isSharedWith = (userId) => {
    return sharedWithState.find((item) => item.UserID === userId);
  };

  const onClick = (userId) => {
    setChanged(true);
    sharedWithState.find((item) => item.UserID === userId)
      ? setSharedWithState((current) =>
          current.filter(({ UserID }) => UserID !== userId)
        )
      : setSharedWithState((current) => [
          ...current,
          users.find(({ UserID }) => UserID === userId),
        ]);
  };

  const renderItem = (item) => (
    <div
      className={isSharedWith(item.UserID) ? "sharedWithUser" : ""}
      onClick={() => onClick(item.UserID)}
    >
      {item.FullName}
    </div>
  );

  const GROUPS = {
    0: "Admin",
    1: "Karosszéria",
    2: "Szervíz",
    3: "Értékesítés",
    4: "Raktár",
  };

  return (
    <ReactModal
      className="R_Modal_Scrollable"
      isOpen={isOpen}
      ariaHideApp={false}
    >
      <input
        className="form-control form-control-lg"
        type="text"
        onChange={(e) => {
          setSearchValue(e.target.value.toLowerCase());
        }}
        placeholder="Írj be egy nevet"
        id="shareModalTextField"
      />
      <ReactSectionList
        keyExtractor={(item, index) => index}
        sections={section(
          users.filter(
            (user) =>
              localStorage.getItem("groupID") === "0" ||
              (user.GroupID > 0 && user.GroupID < 100)
          ),
          "GroupID"
        )}
        renderSectionHeader={(item) => <div>{GROUPS[item.title]}</div>}
        renderItem={renderItem}
        style={{ height: "fit-content" }}
      />
      <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
        <button
          style={{ width: "150px" }}
          onClick={async () => {
            if (await confirm("Elmented a változtatásokat?")) {
              setSearchValue("");
              setChanged(false);
              onSave(sharedWithState);
            }
          }}
          className="btn btn-primary"
          disabled={!changed}
        >
          Mentés
        </button>
        <button
          style={{ width: "150px", marginLeft: "80px" }}
          onClick={() => {
            setSearchValue("");
            setSharedWithState(sharedWith);
            setChanged(false);
            setState(false);
          }}
          className="btn btn-primary"
        >
          {changed ? "Visszaállítás" : "Mégse"}
        </button>
      </div>
    </ReactModal>
  );
}
