import { LoginCheck } from "../static";
import Header from "../components/Header";
import $ from "jquery";
import { useState, useEffect } from "react";
import { useParams, useResolvedPath } from "react-router-dom";
import EventCard from "../components/EventCard";
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";

const URL = process.env.REACT_APP_SITE;

const token = localStorage.getItem("token");

const downloadData = (
  pathName,
  limit,
  pageNum,
  setEventState,
  query,
  setEvents
) => {
  const archived = pathName === "archive";
  const deleted = pathName === "deleted";
  $.ajax({
    url:
      URL +
      "getEventNumber" +
      (archived ? "?archived=true" : "") +
      (deleted ? "?deleted=true" : ""),
    headers: { token },
    success: function (data) {
      const numEvent = data;
      var pageSum = Math.ceil(numEvent / limit);
      setEventState({
        pageSum: pageSum,
        eventNum: numEvent,
      });
      const xOffset = (pageNum - 1) * limit;
      const requestUrl = `getEvents?offset=${xOffset}&limit=${limit}${
            archived ? "&archived=true" : ""
          }${deleted ? "&deleted=true" : ""}${
            query.length > 0 ? "&search=" + query : ""
          }`;
      $.ajax({
        url: URL + requestUrl,
        headers: { token },
        success: function (data) {
          setEvents(data);
        },
        error: function () {
          setEvents([]);
        },
        dataType: "json",
      });
    },
    dataType: "text",
  });
};

function enterBack(id) {
  $("#card" + id).css("background-color", "lightgray");
}

function leaveBack(id) {
  $("#card" + id).css("background-color", "WhiteSmoke");
}

function eventClicked(ev, id, deleted) {
  if (ev.target.nodeName !== "BUTTON") {
    window.open(
      "/event/" + id + deleted,
      "_self"
    );
  }
}

function Pagination(props) {
  const show = props.show;
  const pathName = props.pathName;
  const pageIndex = props.pageIndex;
  const pageCount = props.pageCount;
  const maxShownPages = props.maxShownPages ?? 5;

  if (!show) return null;

  return (
    <nav style={{ display: "flex" }}>
      <ul
        className="pagination justify-content-center"
        style={{ margin: "auto" }}
      >
        <li className={"page-item"} key="first">
          <a className="page-link page_nav_arrow" href={`/${pathName}/1`} title={"Első"}>
            <KeyboardDoubleArrowLeft sx={{color: "dodgerblue", fontSize: 18}}/>
          </a>
        </li>
        <li
          className={
            "page-item" + (parseInt(pageIndex) === 1 ? " disabled" : "")
          }
          key="previous"
        >
          <a
            className="page-link page_nav_arrow"
            href={`/${pathName}/${parseInt(pageIndex) - 1}`}
            title={"Előző"}
          >
            <KeyboardArrowLeft  sx={{color: (parseInt(pageIndex) === 1 ? "gray" : "dodgerblue"), fontSize: 18}}/>
          </a>
        </li>
        {pageCount > maxShownPages && pageIndex > Math.floor(maxShownPages/2) + 1 ? (
          <li className={"page-item disabled"} key="moreBefore">
            <div className="page-link">...</div>
          </li>
        ) : null}
        {Array.from({length: pageCount > maxShownPages ? maxShownPages : pageCount}, (_, i) => i).map((i) => {
          const pageNum = pageCount > maxShownPages ? (Number(pageIndex) + i - Math.floor(maxShownPages/2)) : (i + 1);
          if (pageNum < 1 || pageNum > pageCount) return null;
          return (
            <li
              key={pageNum}
              className={
                pageNum === parseInt(pageIndex)
                  ? "page-item active_c"
                  : "page-item"
              }
            >
              <a className="page-link" href={`/${pathName}/${pageNum}`}>
                {pageNum}
              </a>
            </li>
          );
        })}
        {pageCount > maxShownPages && pageIndex < pageCount - Math.ceil(maxShownPages/2) + 1 ? (
          <li className={"page-item disabled"} key="moreAfter">
            <div className="page-link">...</div>
          </li>
        ) : null}

        <li
          className={
            "page-item" + (parseInt(pageIndex) === pageCount ? " disabled" : "")
          }
          key="next"
        >
          <a
            className="page-link page_nav_arrow"
            href={`/${pathName}/${parseInt(pageIndex) + 1}`}
            title={"Következő"}
          >
            <KeyboardArrowRight sx={{color: (parseInt(pageIndex) === pageCount ? "gray" : "dodgerblue"), fontSize: 18}}/>
          </a>
        </li>
        <li className={"page-item"} key="last">
          <a
            className="page-link page_nav_arrow"
            href={`/${pathName}/${pageCount}`}
            title={"Utolsó"}
          >
            <KeyboardDoubleArrowRight sx={{color: "dodgerblue", fontSize: 18}}/>
          </a>
        </li>
      </ul>
    </nav>
  );
}

function EventList(props) {
  const events = props.events;
  const pathName = props.pathName;
  const downloadData = props.downloadData;

  if (!events) return null;

  if (events.length === 0)
    return (
      <div
        style={{
          display: "flex",
          fontSize: "30px",
          justifyContent: "center",
        }}
      >
        Nincs megjelníthető esemény
      </div>
    );

  return events.map((event, index) => (
    <EventCard
      key={index}
      event={event}
      pathName={pathName}
      eventClicked={eventClicked}
      onMouseEnter={enterBack}
      onMouseLeave={leaveBack}
      downloadData={downloadData}
    />
  ));
}

function Home() {
  const pathName = useResolvedPath().pathname.split("/")[1];

  const [events, setEvents] = useState(null);
  const [eventState, setEventState] = useState({ pageSum: 0, eventNum: 0 });
  const [query, setQuery] = useState("");

  LoginCheck();
  const limit = 10;
  let params = useParams();

  let pageNum = params.pageNum || 1;
  useEffect(
    () =>
      downloadData(
        pathName,
        limit,
        pageNum,
        setEventState,
        query,
        setEvents
      ),
    [query]
  );

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <div
        className="form-outline"
        style={{
          textAlign: "center",
          width: "100%",
          paddingLeft: "30px",
          paddingRight: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <input
          type="search"
          id="search"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          className="form-control"
          style={{
            fontSize: 25,
          }}
          placeholder="Keresés azonosító alapján (pl.: rendszám)"
          aria-label="Search"
        />
        <Pagination
          show={
            eventState.pageSum > 1 && query.length === 0
          }
          pathName={pathName}
          pageIndex={pageNum}
          pageCount={eventState.pageSum}
        />
      </div>
      <EventList
        events={events}
        pathName={pathName}
        downloadData={() =>
          downloadData(
            pathName,
            limit,
            pageNum,
            setEventState,
            query,
            setEvents
          )
        }
      />
      <Pagination
        show={eventState.pageSum > 1 && query.length === 0}
        pathName={pathName}
        pageIndex={pageNum}
        pageCount={eventState.pageSum}
        maxShownPages={9}
      />
    </div>
  );
}

export default Home;
