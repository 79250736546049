import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

function RegistrationDialog({ open, onClose }) {
  const [userError, setUserError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [passConfError, setPassConfError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConf, setPasswordConf] = useState("");
  const [fullname, setFullname] = useState("");
  const [group, setGroup] = useState("");

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getGroups();
  }, []);

  const resetStates = () => {
    setUsername("");
    setPassword("");
    setPasswordConf("");
    setFullname("");
    setGroup("");
  };

  const handleClose = () => {
    resetStates();
    onClose(null);
  };

  const handleRegister = async () => {
    if (username && password && fullname) {
      registerUser();
      return;
    }

    username ? setUserError(false) : setUserError(true);
    password ? setPassError(false) : setPassError(true);
    passwordConf ? setPassConfError(false) : setPassConfError(true);
    fullname ? setNameError(true) : setNameError(false);
  };

  function registerUser() {
    const URL = process.env.REACT_APP_SITE;

    const postData = {
      username: username,
      password: password,
      fullname: fullname,
      groupID: group,
    };

    fetch(URL + "register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: localStorage.getItem("token"),
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          resetStates();
          onClose(response);
        } else {
          resetStates();
          onClose(response);
        }
      });
  }

  const renderGroupSelector = () => (
    <div>
      <FormLabel
        sx={{
          margin: "10px",
        }}
        component="legend"
      >
        Szerepkör:
      </FormLabel>
      <RadioGroup
        sx={{
          margin: "10px",
        }}
        value={group}
        onChange={(e) => {
          setGroup(e.target.value);
        }}
      >
        {groups.map((group) => (
          <FormControlLabel
            value={group.GroupID}
            key={group.GroupID}
            control={<Radio />}
            label={group.GroupName}
          />
        ))}
      </RadioGroup>
    </div>
  );

  function getGroups() {
    const URL = process.env.REACT_APP_SITE;

    fetch(URL + "groups", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.error(response.error);
          return;
        }
        setGroups(response);
      });
  }

  return (
    <Dialog fullWidth={true} maxWidth={"xl"} onClose={handleClose} open={open}>
      <DialogTitle>Új felhasználó létrehozása</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Mégse</Button>
      </DialogActions>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <FormControl required={true} error={userError}>
          <InputLabel htmlFor="felhasználónév">Felhasználónév</InputLabel>
          <Input
            id="felhasználónév"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </FormControl>
        <br />
        <FormControl required={true} error={passError}>
          <InputLabel htmlFor="jelszó">Jelszó</InputLabel>
          <Input
            id="jelszó"
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </FormControl>
        <br />
        <FormControl
          required={true}
          error={passConfError}
          color={password !== passwordConf ? "error" : "primary"}
        >
          <InputLabel htmlFor="jelszómeg">Jelszó megerősítése</InputLabel>
          <Input
            id="jelszómeg"
            type="password"
            value={passwordConf}
            onChange={(event) => {
              setPasswordConf(event.target.value);
            }}
          />
        </FormControl>
        <br />
        <FormControl required={true} error={nameError}>
          <InputLabel htmlFor="fullname">Teljes Név</InputLabel>
          <Input
            id="fullname"
            value={fullname}
            onChange={(event) => {
              setFullname(event.target.value);
            }}
          />
        </FormControl>
        <br />
        <Stack direction="row">{renderGroupSelector()}</Stack>

        <Button
          sx={{
            margin: "10px",
          }}
          variant="contained"
          onClick={handleRegister}
          disabled={
            !group ||
            password !== passwordConf ||
            !username ||
            !password ||
            !passwordConf ||
            !fullname
          }
        >
          Regisztráció
        </Button>
      </div>
    </Dialog>
  );
}

export default RegistrationDialog;
