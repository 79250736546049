import { Dialog, DialogTitle } from "@mui/material";
import QRCode from "react-qr-code";

function DownloadApkModal(props) {
  const handleClose = props.onClose;
  const open = props.open;
  const URL = process.env.REACT_APP_SITE;

  return (
    <Dialog open={open} onClickCapture={handleClose}>
      <DialogTitle>Alkalmazás letöltése</DialogTitle>
      
      <div className="downloadApkText">
      <QRCode value={URL +"Mediatar.apk"}/><br></br>
        Scaneld be a fenti QR kódot vagy kattints{" "}
        <a href={URL +"Mediatar.apk"}>IDE</a> a
        Médatár alkalmazás letöltéséhez!
      </div>
    </Dialog>
  );
}

export default DownloadApkModal;
